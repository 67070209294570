@import '../../style/custonVariable';

.downloadConatiner {
    padding: 15px;

    .userDowmloadSection {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        grid-gap: 17px;

        .content {
            display: grid;
            grid-template-columns: 65px 5fr 3fr;
            // grid-gap: 10px;
            grid-gap: 15px;
            padding: 25px 10px;
            margin-bottom: 0px;
            // border-radius: 2px;
            border-radius: 2%;
            box-shadow: 0 0 0.875rem 0 rgba(53, 64, 82, 0.06);
            background-color: #fff;
            border: 1px solid transparent;

            .placeright {
                float: right;

                button {
                    background-color: #34a54f;
                    border-color: #34a54f;
                    color: white;
                    padding: 12px 9px;
                }
            }
            img{
                width: 100%;
            }
        }
    }
}

.linkcoloor {
    color: white;

    &:hover {
        color: white;
    }
}

.snddiv {
    max-width: 150px;
}



.inviteContainer {
    padding: 15px;

    .cardBody {
        padding: 15px;
    }

    .textinviteContent {
        border: 1px solid transparent;
        border-color: #dee5e7;
        padding: 15px;

        .formControl {
            margin-bottom: 15px;
        }
    }

    .row {
        margin-right: -15px;
        margin-left: -15px;
    }

    .socialItem {
        display: block;
        position: relative;
        margin-bottom: 30px;

        .user {
            width: 88px;
            height: 88px;
            position: relative;
            position: absolute;
            top: -10px;
            left: 25px;
            z-index: 3;
            border-radius: 5px;
            overflow: hidden;
            background: #fff;

            img {
                max-width: 100%;
                width: 100%;
                vertical-align: middle;
            }
        }

        .socialContent {
            background: #f0f3f4;
            overflow: hidden;
            // box-shadow: 0 2px 48px 0;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            position: relative;

            h3 {
                display: block;
                font-weight: 400;
                font-size: 16px;
                color: #3B566E;
                margin-top: 20px;
                padding-left: 130px;
                letter-spacing: .69px;
                margin-bottom: 5px;
                position: relative;
                z-index: 3;
            }

            span {
                display: block;
                font-weight: 400;
                font-size: 14px;
                color: #6F8BA4;
                letter-spacing: .6px;
                padding-left: 130px;
                padding-right: 25px;
                position: relative;
                z-index: 3;
            }

            .text {
                position: relative;
                overflow: hidden;

                p {
                    padding-left: 25px;
                    padding-right: 25px;
                    margin-bottom: 25px;
                    margin-top: 40px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #6F8BA4;
                    letter-spacing: .6px;
                    line-height: 26px;
                    position: relative;
                    z-index: 3;
                }
            }

            .shareBtn {
                padding: 15px;
            }
        }
    }
}

.invitesEmailItem {
    display: block;
    position: relative;
    margin-bottom: 30px;

    .emailThumbnail {
        width: 88px;
        height: 88px;
        position: absolute;
        top: -10px;
        left: 25px;
        z-index: 3;
        -webkit-transition: all .3s ease 0;
        -moz-transition: all .3s ease 0;
        -o-transition: all .3s ease 0;
        transition: all .3s ease 0;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        overflow: hidden;
        background: #fff;

        img {
            max-width: 100%;
        }
    }

    .inviteItemContent {
        background: #f0f3f4;
        overflow: hidden;
        // box-shadow: 0 2px 48px 0 rgb(0 0 0 / 6%);
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        position: relative;

        .historyDate {
            display: block;
            font-weight: 400;
            font-size: 12px;
            color: #6F8BA4;
            letter-spacing: .6px;
            padding-left: 130px;
            padding-right: 25px;
            position: relative;
            z-index: 3;

            .textPrimary {
                color: $color-primary;
            }

            .textSuccess {
                color: $color-success;
            }
        }

        p {
            font-weight: 400;
            font-size: 14px;
            color: #6F8BA4;
            letter-spacing: .6px;
            line-height: 26px;
            position: relative;
            z-index: 3;
        }

        .textContent {
            position: relative;
            overflow: hidden;
            padding-left: 25px;
            padding-right: 25px;
            margin-bottom: 25px;
            margin-top: 40px;
            font-weight: 400;
            font-size: 14px;
            color: #6F8BA4;
            letter-spacing: .6px;
            line-height: 26px;
            position: relative;
            z-index: 3;

            &:before {
                font-family: FontAwesome;
                content: "\f10d";
                position: absolute;
                left: 25px;
                top: 25px;
                color: #3B566E;
                z-index: 1;
                font-size: 40px;
                opacity: .4;
                color: #e1ecf4;
            }
        }

        .previewBtn {
            margin: 15px;
        }



        .userName {
            display: block;
            font-weight: 400;
            font-size: 16px;
            color: #3B566E;
            margin-top: 20px;
            padding-left: 130px;
            letter-spacing: .69px;
            margin-bottom: 5px;
            position: relative;
            z-index: 3;
        }

        span {
            display: block;
            font-weight: 400;
            font-size: 14px;
            color: #6F8BA4;
            letter-spacing: .6px;
            padding-left: 130px;
            padding-right: 25px;
            position: relative;
            z-index: 3;
        }

        // &:before {
        //     content: '';
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     z-index: 1;
        //     background-image: linear-gradient(135deg,#004e92 0%,#000428 100%);
        //     opacity: 0;
        // }

    }
}

.bannerItem {
    display: block;

    .bannerImage {
        width: 35%;
        float: left;

        img {
            display: block;
            width: 100%;
            border: 1px solid #ddd;
            padding: 7px;
            height: 198px;
        }
    }

    .bannerContent {
        padding-left: 20px;
        width: 65%;
        float: right;

        .postHeader {
            padding: 0;

            .postTitle {
                font-size: 18px;
                line-height: 28px;
            }

            .copyBtn {
                color: #fff !important;
                background-color: #7266ba !important;
                border-color: #7266ba;
                padding: 9px 12px;
            }
        }
    }
}

.textArea {
    font-size: 15px;
    height: 70px !important;
}

//news content old css start
.newsContent {
    padding: 15px;

    .image {
        padding: 2px;
        border: 1px solid #dee5e7;
        display: inline-block;
        width: 50px;
        margin-right: 15px;

        img {
            width: 100%;
            max-width: 100%;
            vertical-align: middle;
        }
    }

    .details {
        display: grid;
        grid-template-columns: 1fr 4fr;
        // grid-gap: 9px;
        overflow: hidden;

        .detailsData {
            display: inline-block;

            .link {
                color: black !important;
            }

        }

        a {
            color: black;
        }
    }

    .Button {
        background-color: $color-primary;

        .readMore {
            color: $color-white;
        }
    }
}

.link {
    color: black !important;
}

.link:hover {
    color: blue !important;
}

//news content old css end


//news content2 new css start
.newsContent2 {
    padding: 15px;

    .totalview {
        // display: grid;
        // grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
        // grid-gap: 17px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 17px;


        .image {
            padding: 2px;
            // border: 1px solid #dee5e7;
            display: inline-block;
            width: 50px;
            margin-right: 15px;

            img {
                width: 100%;
                max-width: 100%;
                vertical-align: middle;
            }
        }

        .details {
            display: grid;
            grid-template-columns: 1fr 4fr;
            // grid-gap: 9px;
            overflow: hidden;

            .detailsData {
                display: inline-block;
            }
        }

        .Button {
            background-color: $color-primary;
            position: absolute;
            bottom: 10%;
            border: none;

            .readMore {
                color: $color-white;
            }
        }
    }
}

.news_imgview {
    border: 1px solid #dee5e7;
    padding: 2px;
}

.newscaard {
    min-height: 170px;
}

//news content2 new css end



.ViewNewsContainer {
    padding: 15px;

    .newsImage {
        width: 100%;
        height: 260px;
    }

    .NewsDetails {
        padding: 30px;

        .title {
            text-align: center;
            font-size: 30px;
        }

        .divider {
            border-color: #d4d6d6;
            border-bottom: 1px solid #dee5e7;
            margin-top: 15px;
            margin-bottom: 15px;
            width: 100%;
            height: 2px;
            margin: 6px 0;
            overflow: hidden;
            font-size: 0;
        }

        .dateDetails {
            font-size: 15px;
            font-weight: 400;
            color: #7266ba;
        }
    }

    .recentNewsList {
        border-bottom: 1px solid #dee5e7;
        margin-bottom: 20px;

        .linkContent {
            display: grid;
            grid-template-columns: 1fr 4fr;

            .NewsLinkButton {
                padding: 2px;
                border: 1px solid #dee5e7;
                display: inline-block;
                width: 50px;
                margin-right: 15px;

                img {
                    width: 100%;
                    min-width: 100%;
                    min-height: 100%;
                }
            }
        }
    }
}

.FaqContainer {
    padding: 15px;

    .FaqContent {
        padding: 15px;

        .panelHeading {
            background-color: #f6f8f8;
            padding: 0;
            cursor: pointer;
        }
    }
}

.ModalDialog {
    position: fixed;
    margin: auto;
    width: 50%;
    height: 100%;
    right: 0;
    max-width: 50%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);

    .ModalHeader {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);

        button {
            margin-right: 25px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #b9b6b6;
        }
    }

    .ModalBodyHeader {
        font-size: 30px;
        color: #3b5698;
        margin-top: 0px;
    }
}

.replicaContent {
    h4 {
        margin-bottom: 10px;
    }

    .uploadContainer {
        max-width: 400px;

        img {
            width: 100%;
            border-radius: 5px;
        }

        margin-bottom: 40px;
    }
}

.dropzoneWrapper {
    border: 2px dashed #c5bfec;
    position: relative;
    height: 120px;
    border-radius: 10px;
    max-width: 400px;

    p {
        color: #7266ba;

    }

    i {
        color: #7266ba;
        font-size: 19px;
    }

    .dropzoneDesc {
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        text-align: center;
        width: 90%;
        top: 20px;
        font-size: 10px;
        color: black;

    }

    .dropzone {
        position: absolute;
        outline: none !important;
        width: 100%;
        height: 100px;
        cursor: pointer;
        opacity: 0;
    }
}

.previewZone {
    max-width: 400px;

    .box {
        position: relative;
        background: #ffffff;
        width: 100%;
        box-shadow: none;
        border-radius: 0;
        margin-bottom: 0;

        .boxHeader {
            color: #444;
            display: block;
            padding: 0px;
            position: relative;
            border-bottom: 0px solid #f4f4f4;
            margin-bottom: 12px;
            margin-top: 25px;

            .boxTools {
                position: absolute;
                right: 10px;
                top: 5px;
                float: right !important;

                button {
                    display: inline-block;
                    padding: 6px 12px;
                    margin-bottom: 0;
                    font-size: 14px;
                    line-height: 1.42857143;
                    text-align: center;
                    white-space: nowrap;
                    vertical-align: middle;
                    -ms-touch-action: manipulation;
                    touch-action: manipulation;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    background-image: none;
                    border: 1px solid transparent;

                    color: #fff;
                    background-color: #d9534f;
                    border-color: #d43f3a;

                    padding: 1px 5px;
                    font-size: 12px;
                    line-height: 1.5;

                    font-weight: 500;
                    border-radius: 2px;
                    outline: 0 !important;
                }
            }
        }

        .boxBody {
            img {
                width: 100%;
            }
        }
    }
}

.inviteEditContainer {
    padding: 15px;

    .backButton {
        text-align: right;
    }

    .cardBody {
        padding: 15px;

        .quots {
            color: #858585;
            font-weight: 400;
            font-size: 12px;
        }
    }
}

.uploadButton {
    background-color: $color-primary;
    border: none;
    margin: 10px;
}

.accordain_icons {
    float: right;
    margin: 0;
    position: relative;
    transform: translateY(-50%);
    font-size: 21px;
    top: 22px;
}


.news_card_box {
    width: 100%;
    display: inline-block;
    text-align: center;
}

.news_card_box img {
    width: auto !important;
    height: auto !important;
    max-width: 100%;
    max-height: 100%;
}


/******************** dark mode *************************************************/




@import '../../style/custonVariable';

.downloadConatiner {
    padding: 15px;

    .userDowmloadSection {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        grid-gap: 17px;

        .dark_mode_content {
            display: grid;
            grid-template-columns: 65px 5fr 3fr;
            // grid-gap: 10px;
            grid-gap: 15px;
            padding: 25px 10px;
            margin-bottom: 0px;
            // border-radius: 2px;
            border-radius: 2%;
            box-shadow: 0 0 0.875rem 0 rgba(53, 64, 82, 0.06);
            background-color: rgba(233, 236, 239, .0784313725490196) !important;
            border: 1px solid transparent;

            .placeright {
                float: right;

                button {
                    background-color: #34a54f;
                    border-color: #34a54f;
                    color: white;
                    padding: 12px 9px;
                }
            }

        }
    }
}

.linkcoloor {
    color: white;

    &:hover {
        color: white;
    }
}

.snddiv {
    max-width: 150px;
}



.inviteContainer {
    padding: 15px;

    .cardBody {
        padding: 15px;
    }

    .textinviteContent {
        border: 1px solid transparent;
        border-color: #dee5e7;
        padding: 15px;

        .formControl {
            margin-bottom: 15px;
        }
    }

    .row {
        margin-right: -15px;
        margin-left: -15px;
    }

    .socialItem {
        display: block;
        position: relative;
        margin-bottom: 30px;

        .user {
            width: 88px;
            height: 88px;
            position: relative;
            position: absolute;
            top: -10px;
            left: 25px;
            z-index: 3;
            border-radius: 5px;
            overflow: hidden;
            background: #fff;

            img {
                max-width: 100%;
                width: 100%;
                vertical-align: middle;
            }
        }

        .dark_mode_socialContent {
            background: #1c2f46 !important;
            overflow: hidden;
            // box-shadow: 0 2px 48px 0;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            position: relative;

            h3 {
                display: block;
                font-weight: 400;
                font-size: 16px;
                color: #3B566E;
                margin-top: 20px;
                padding-left: 130px;
                letter-spacing: .69px;
                margin-bottom: 5px;
                position: relative;
                z-index: 3;
            }

            span {
                display: block;
                font-weight: 400;
                font-size: 14px;
                color: #6F8BA4;
                letter-spacing: .6px;
                padding-left: 130px;
                padding-right: 25px;
                position: relative;
                z-index: 3;
            }

            .text {
                position: relative;
                overflow: hidden;

                p {
                    padding-left: 25px;
                    padding-right: 25px;
                    margin-bottom: 25px;
                    margin-top: 40px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #6F8BA4;
                    letter-spacing: .6px;
                    line-height: 26px;
                    position: relative;
                    z-index: 3;
                }
            }

            .shareBtn {
                padding: 15px;
            }
        }
    }
}

.invitesEmailItem {
    display: block;
    position: relative;
    margin-bottom: 30px;

    .emailThumbnail {
        width: 88px;
        height: 88px;
        position: absolute;
        top: -10px;
        left: 25px;
        z-index: 3;
        -webkit-transition: all .3s ease 0;
        -moz-transition: all .3s ease 0;
        -o-transition: all .3s ease 0;
        transition: all .3s ease 0;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        overflow: hidden;
        background: #fff;

        img {
            max-width: 100%;
        }
    }

    .dark_mode_inviteItemContent {
        background: #1c2f46 !important;
        overflow: hidden;
        // box-shadow: 0 2px 48px 0 rgb(0 0 0 / 6%);
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        position: relative;

        .historyDate {
            display: block;
            font-weight: 400;
            font-size: 12px;
            color: #6F8BA4;
            letter-spacing: .6px;
            padding-left: 130px;
            padding-right: 25px;
            position: relative;
            z-index: 3;

            .textPrimary {
                color: $color-primary;
            }

            .textSuccess {
                color: $color-success;
            }
        }

        p {
            font-weight: 400;
            font-size: 14px;
            color: #6F8BA4;
            letter-spacing: .6px;
            line-height: 26px;
            position: relative;
            z-index: 3;
        }

        .dark_mode_textContent {
            position: relative;
            overflow: hidden;
            padding-left: 25px;
            padding-right: 25px;
            margin-bottom: 25px;
            margin-top: 40px;
            font-weight: 400;
            font-size: 14px;
            color: white !important;
            letter-spacing: .6px;
            line-height: 26px;
            position: relative;
            z-index: 3;

            &:before {
                font-family: FontAwesome;
                content: "\f10d";
                position: absolute;
                left: 25px;
                top: 25px;
                color: #ffffff !important;
                z-index: 1;
                font-size: 40px;
                opacity: .4;
                //color: #e1ecf4;
            }
        }

        .previewBtn {
            margin: 15px;
        }



        .dark_mode_userName {
            display: block;
            font-weight: 400;
            font-size: 16px;
            color: #ffffff;
            margin-top: 20px;
            padding-left: 130px;
            letter-spacing: .69px;
            margin-bottom: 5px;
            position: relative;
            z-index: 3;
        }

        span {
            display: block;
            font-weight: 400;
            font-size: 14px;
            color: #dddfe0;
            letter-spacing: .6px;
            padding-left: 130px;
            padding-right: 25px;
            position: relative;
            z-index: 3;
        }

        // &:before {
        //     content: '';
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     z-index: 1;
        //     background-image: linear-gradient(135deg,#004e92 0%,#000428 100%);
        //     opacity: 0;
        // }

    }
}

.bannerItem {
    display: block;

    .bannerImage {
        width: 35%;
        float: left;

        img {
            display: block;
            width: 100%;
            border: 1px solid #ddd;
            padding: 7px;
            height: 198px;
        }
    }

    .bannerContent {
        padding-left: 20px;
        width: 65%;
        float: right;

        .postHeader {
            padding: 0;

            .postTitle {
                font-size: 18px;
                line-height: 28px;
            }

            .copyBtn {
                color: #fff !important;
                background-color: #7266ba !important;
                border-color: #7266ba;
                padding: 9px 12px;
            }
        }
    }
}

.dark_mode_textArea {
    font-size: 15px;
    height: 70px !important;
    background-color: #253548 !important;
    color: white !important;
    border: none;
}

//news content old css start
.newsContent {
    padding: 15px;

    .image {
        padding: 2px;
        border: 1px solid #dee5e7;
        display: inline-block;
        width: 50px;
        margin-right: 15px;

        img {
            width: 100%;
            max-width: 100%;
            vertical-align: middle;
        }
    }

    .details {
        display: grid;
        grid-template-columns: 1fr 4fr;
        // grid-gap: 9px;
        overflow: hidden;

        .detailsData {
            display: inline-block;

            .dark_mode_link {
                color: white !important;
            }

        }

        a {
            color: black;
        }
    }

    .Button {
        background-color: $color-primary;

        .readMore {
            color: $color-white;
        }
    }
}

.dark_mode_link {
    color: white !important;
}

.link:hover {
    color: blue !important;
}

//news content old css end


//news content2 new css start
.newsContent2 {
    padding: 15px;

    .totalview {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 17px;


        .image {
            padding: 2px;
            // border: 1px solid #dee5e7;
            display: inline-block;
            width: 50px;
            margin-right: 15px;

            img {
                width: 100%;
                max-width: 100%;
                vertical-align: middle;
            }
        }

        .details {
            display: grid;
            grid-template-columns: 1fr 4fr;
            // grid-gap: 9px;
            overflow: hidden;

            .detailsData {
                display: inline-block;
            }
        }

        .Button {
            background-color: $color-primary;
            position: absolute;
            bottom: 10%;
            border: none;

            .readMore {
                color: $color-white;
            }
        }
    }
}

.news_imgview {
    border: 1px solid #dee5e7;
    padding: 2px;
}

.newscaard {
    min-height: 170px;
}

//news content2 new css end



.ViewNewsContainer {
    padding: 15px;

    .newsImage {
        width: 100%;
        height: 260px;
    }

    .NewsDetails {
        padding: 30px;

        .dark_mode_title {
            text-align: center;
            font-size: 30px;
            color: white !important;
        }

        .divider {
            border-color: #d4d6d6;
            border-bottom: 1px solid #dee5e7;
            margin-top: 15px;
            margin-bottom: 15px;
            width: 100%;
            height: 2px;
            margin: 6px 0;
            overflow: hidden;
            font-size: 0;
        }

        .dateDetails {
            font-size: 15px;
            font-weight: 400;
            color: #7266ba;
        }
    }

    .recentNewsList {
        border-bottom: 1px solid #dee5e7;
        margin-bottom: 20px;

        .linkContent {
            display: grid;
            grid-template-columns: 1fr 4fr;

            .dark_mode_NewsLinkButton {
                padding: 2px;
                border: 1px solid #dee5e7;
                display: inline-block;
                width: 50px;
                margin-right: 15px;
                text-decoration: none;
                color: white !important;

                img {
                    width: 100%;
                }
            }
        }
    }
}

.FaqContainer {
    padding: 15px;

    .FaqContent {
        padding: 15px;

        .panelHeading {
            background-color: #f6f8f8;
            padding: 0;
            cursor: pointer;
        }
    }
}

.ModalDialog {
    position: fixed;
    margin: auto;
    width: 50%;
    height: 100%;
    right: 0;
    max-width: 50%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);

    .ModalHeader {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);

        button {
            margin-right: 25px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #b9b6b6;
        }
    }

    .ModalBodyHeader {
        font-size: 30px;
        color: #3b5698;
        margin-top: 0px;
    }
}

.dark_mode_replicaContent {
    h4 {
        margin-bottom: 10px;
        color: white !important;
    }

    .uploadContainer {
        max-width: 400px;

        img {
            width: 100%;
            border-radius: 5px;
        }

        margin-bottom: 40px;
    }
}

.dropzoneWrapper {
    border: 2px dashed #c5bfec;
    position: relative;
    height: 120px;
    border-radius: 10px;
    max-width: 400px;

    p {
        color: #7266ba;

    }

    i {
        color: #7266ba;
        font-size: 19px;
    }

    .dropzoneDesc {
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        text-align: center;
        width: 90%;
        top: 20px;
        font-size: 10px;
        color: black;

    }

    .dropzone {
        position: absolute;
        outline: none !important;
        width: 100%;
        height: 100px;
        cursor: pointer;
        opacity: 0;
    }
}

.previewZone {
    max-width: 400px;

    .box {
        position: relative;
        background: #ffffff;
        width: 100%;
        box-shadow: none;
        border-radius: 0;
        margin-bottom: 0;

        .boxHeader {
            color: #444;
            display: block;
            padding: 0px;
            position: relative;
            border-bottom: 0px solid #f4f4f4;
            margin-bottom: 12px;
            margin-top: 25px;

            .boxTools {
                position: absolute;
                right: 10px;
                top: 5px;
                float: right !important;

                button {
                    display: inline-block;
                    padding: 6px 12px;
                    margin-bottom: 0;
                    font-size: 14px;
                    line-height: 1.42857143;
                    text-align: center;
                    white-space: nowrap;
                    vertical-align: middle;
                    -ms-touch-action: manipulation;
                    touch-action: manipulation;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    background-image: none;
                    border: 1px solid transparent;

                    color: #fff;
                    background-color: #d9534f;
                    border-color: #d43f3a;

                    padding: 1px 5px;
                    font-size: 12px;
                    line-height: 1.5;

                    font-weight: 500;
                    border-radius: 2px;
                    outline: 0 !important;
                }
            }
        }

        .boxBody {
            img {
                width: 100%;
            }
        }
    }
}

.inviteEditContainer {
    padding: 15px;

    .backButton {
        text-align: right;
    }

    .cardBody {
        padding: 15px;

        .quots {
            color: #858585;
            font-weight: 400;
            font-size: 12px;
        }
    }
}

.uploadButton {
    background-color: $color-primary;
    border: none;
    margin: 10px;
}

.accordain_icons {
    float: right;
    margin: 0;
    position: relative;
    transform: translateY(-50%);
    font-size: 21px;
    top: 22px;
}

.dark_mode_heading {
    color: white !important
}

.dark_mode_content {
    color: #cbcbcb !important;
}

.dark_mode_recent_news_title {
    color: #cbcbcb !important;
}

/*********************** end of dark mode ***********************************/

.search{
border: 1px solid;
border-color: #dee5e7;
border-radius: 3px;
// box-shadow: 0 0 0.875rem 0 rgb(53 64 82 / 6%);
box-shadow: 0 0 0.875rem 0 rgba(53, 64, 82, 0.06);
}