.app{
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 100%;
    background:#f8f8fb;
    &:after{
        position: absolute;
        top: 0; 
        bottom: 0;
        z-index: -1;
        display: block;
        width: inherit;
        background-color: #fff; 
        border: inherit;
        content: "";
    }
}
.loginContainer{
    width: 450px !important;
    max-width: 100%;
    background-color: rgba(255,255,255,1) !important;
    padding: 30px 12px 25px 13px;
   // border-radius: 2px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
   // box-shadow: 0 0.75rem 2.5rem rgb(18 ,38, 63 , 12%)  0px 7px 29px 0px;
    border-radius: 20px;
    // box-shadow: 0px 1px 8px rgb(0 0 0 / 20%);
    margin-top:2%;
    position: relative;
    .langContainer{display: block !important;}
  }
  .dark_mode_style_bgPrimary{
    background-color: none;
    margin-bottom: 5%;

  }
  .bgPrimary{
    background-color: #dbf4ff !important;
    margin-bottom: 5%;

  }
  .bgLogin{
    padding: 2px;
  }

  .dark_mode_lg_login_btn_btm_1{
    width: 100%;
    text-align: center;
    color: #fff;
    margin-top: 20px;
    font-size: 14px;
    text-decoration: none;
    a{
        width: 100%;
        text-align: center;
        color: #70A9FF;
        font-size: 14px;
        text-decoration: none;
        &:hover{color: #3b6fbd;}
    }
}



/*********************************************** dark mode **************************************/ 
.dark_mode_app{
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 100%;
    background:#061E40 !important;
    &:after{
        position: absolute;
        top: 0; 
        bottom: 0;
        z-index: -1;
        display: block;
        width: inherit;
        background-color: #061E40 !important; 
        border: inherit;
        content: "";
    }
}
.dark_mode_loginContainer{
    width: 500px !important;
    max-width: 100%;
    background:#ffffff0d !important;
    padding: 20px 30px 25px 30px;
    border-radius: 2px;
    margin-top:7%;
    position: relative;
    .langContainer{display: block !important;}
    input{background-color: rgba(255,255,255,0.2);color: #fff;}
  }

  .lg_login_btn_btm_1{
    width: 100%;
    text-align: center;
    color: #242424;
    // background-color:white !important;
    margin-top: 20px;
    font-size: 14px;
    margin-bottom: 50px;
    text-decoration: none;
    a{
        width: 100%;
        text-align: center;
        color: #70A9FF;
        font-size: 14px;
        text-decoration: none;
        &:hover{color: #3b6fbd;}
    }
}

/********************************************* end dark mode ************************************/
  
  
