@import '../../style/custonVariable';

.Maincontainer{
    position: relative;
    // background: $panel-bg-color;
    height: 100%;
    margin-top: $tiles-gap-height;
    @media (max-width: 768px) {
        margin-top: 10px;
    }
    .epinTiles{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(23%, 1fr));
        grid-gap: $tiles-grid-gap;
        margin-top: $tiles-margin-top;
        padding: $tiles-padding;
        margin: $tiles-margin;
        max-width: 100%;
        // @media (min-width: 1600px) { 
        //     max-width: 80%;
        // }
        @media (max-width: 991px) { 
            grid-template-columns: 1fr;
        }
        .Tiles{
            min-height: $tiles-min-height;
            background-color: $panel-bg-color;
            padding:15px 20px;
            // border-radius: $tile-single-border-radius;
            border-radius:10px;
            position: relative;
            box-shadow: 0px 1px 3px rgba(0,0,0,0.1);
            align-items: center;
            margin-bottom: 0px;
            margin-top: -$tiles-gap-height+10;
            @media (max-width: 991px) {
                margin-top: 0;
              }
              .leftIcon{
                padding: $tile-single-left-icon-padding;
                margin-right: $tile-single-left-icon-marginRight;
                // border-radius: $tile-single-left-icon-BorderRadius;
                border-radius: 10%;
                width: $tile-single-left-icon-width-height;
                height: $tile-single-left-icon-width-height;
                display: table;
                align-items: center;
                &.green{
                    background: linear-gradient(to top, #5bc554 0%, #11a666 100%);               
                    }
                &.blue{
                        background: linear-gradient(45deg, #44badc, #519eaf);
                    }
                &.red{
                        background: linear-gradient(45deg, #e92222cc,#bd2727);
                    }
                &.yellow{
                        background: linear-gradient(45deg, #f3e214, #e7d820);
                    }
                img{
                    width: 100%;
                }
            }
            .tilesData{
                h4{
                    font-family: $font-mulish !important;
                    font-size: 17px;
                    color:  rgb(88, 102, 110);
                }
                .TilesAmount{
                    color: rgb(88, 102, 110);
                    font-size: 22px;
                }
            }
        }
    }
    .summary{
        padding : $summary-padding;
        position: relative;
        margin-bottom: $summary-margin-bottom;
        .tabContent{
            flex-wrap: wrap;
            width: 100%;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
            padding: $table-content-padding;
            background: #fff;
            // border-radius: $table-content-border-radius;
            border-radius: 2%;
            .TabPanel{
                border: none;
                padding: 0 1em;
                padding-top: 2px;
                @media only screen and (max-width: 991px){
                    width: 100%;
                }
                @media only screen and (max-width: 600px){
                    width: 100%;
                    padding: 0px;
                }
            }
            .dataTable{
                padding-top: $tab-content-padding-top;
                .filterSection{
                    .filterDropDown{
                        height: 36px !important;
                        min-width: 155px;
                        border-radius: 0px !important;
                        border: 1px solid #cfdadd !important;
                        width: 150px;
                        .selectedCatgory{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            padding: 5px 19px !important;
                            cursor: pointer;                            
                            .caretIcon{
                                display: inline-block;
                                width: 0;
                                height: 0;
                                margin-left: 5px;
                                vertical-align: middle;
                                border-top: 4px dashed;
                                border-right: 4px solid transparent;
                                border-left: 4px solid transparent;
                                position: absolute;
                                right: 10%;
                                top: 40%;
                            }
                        }
                        @media only screen and (max-width: 600px){
                            min-width: 100%;
                        }
                    }
                } 
                .filterButtons{
                    button{
                        padding: 10px;
                        margin-left: 5px;
                        @media only screen and (max-width: 907px){
                            margin-top: 20px;
                        }
                    }
                }
                .tables{
                    padding-top: 10px;
                    font-family: system-ui;
                   
                }
            }
        }
    }
}

.badge{
    display: inline-block;
    padding: .35em .5em;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 3.25rem;
    min-width: 10px; 
    text-shadow: 0 1px 0 rgba(252, 213, 213, 0.2);
    &.badgegreen{
        color: #11a666;
        background-color: #dbf2e8;
    }
    &.badgeblue{        
        color: #7266ba;
        background-color: #7266ba54;
    }
}
.status{
    font-size: 15px;
    &.active{
        color: #11a666;
    }
    &.used{
        color: #FF8C00;
    }
    &.expired{
        color: #dc3545;
    }
}

.ModalDialog{
    position: fixed;
    margin: auto;
    width: 35%;
    height: 100%;
    right: 0;
    max-width: 50%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
    .ModalHeader{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
        button{
            // margin-right: 25px; 
            margin-right: -6%;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #b9b6b6;
        }
    }
    .ModalBodyHeader{
        font-size: 30px;
        color: #3b5698;
        margin-top: 0px;
        padding-bottom: 18px;
    }
}
.refundText{
    color: red;
}
.required::after{
    content: "*";
    color: red;
    padding-left: 5px;
}
.isInvalid{
    margin-top: -1px !important;
    color: #a94442;
}
.Forms{
    // background-color: #f7f7f7 !important;
    padding: 5px 12px !important;
    border-radius: 3px!important;
    border: 1px solid #cfdadd;
    height: 34px;
    color: black !important;
    &:disabled{
        cursor: not-allowed;
    }
}
.submitButton{
    background-color: $color-primary;
    height: $input-height;
    border-color: $color-primary;
    &:hover{
        background-color: $color-primary;
        border-color: $color-primary;
    }
    &:focus{
        background-color: $color-primary;
        border-color: $color-primary;
    }
}

.submitButton:active{
    background-color: $color-primary !important;
    border-color: $color-primary !important;
}

.reqepindatevalid{
    position: relative;
    top: -19px;
}
@media only screen and (max-width: 907px){
    .multiselect-container{margin-bottom: 20px;}
}


/***************************** dark mode ***********************************************/

.Maincontainer {
    position: relative;
    // background: $panel-bg-color;
    height: 100%;
    margin-top: $tiles-gap-height;

    @media (max-width: 768px) {
        margin-top: 10px;
    }

    .epinTiles {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(23%, 1fr));
        grid-gap: $tiles-grid-gap;
        margin-top: $tiles-margin-top;
        padding: $tiles-padding;
        margin: $tiles-margin;
        max-width: 100%;

        // @media (min-width: 1600px) { 
        //     max-width: 80%;
        // }
        @media (max-width: 991px) {
            grid-template-columns: 1fr;
        }

        .dark_mode_Tiles {
            min-height: $tiles-min-height;
            background-color: hsla(0, 0%, 100%, .058823529411764705) !important;
            ;
            //padding: $tile-single-padding;
            padding: 15px 20px;
            // border-radius: $tile-single-border-radius;
            border-radius: 3%;
            position: relative;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
            align-items: center;
            margin-bottom: 0px;
            margin-top: -$tiles-gap-height+10;

            @media (max-width: 991px) {
                margin-top: 0;
            }

            .leftIcon {
                padding: $tile-single-left-icon-padding;
                margin-right: $tile-single-left-icon-marginRight;
                // border-radius: $tile-single-left-icon-BorderRadius;
                border-radius: 10%;
                width: $tile-single-left-icon-width-height;
                height: $tile-single-left-icon-width-height;
                display: table;
                align-items: center;

                &.green {
                    background: linear-gradient(to top, #5bc554 0%, #11a666 100%);
                }

                &.blue {
                    background: linear-gradient(45deg, #44badc, #519eaf);
                }

                &.red {
                    background: linear-gradient(45deg, #e92222cc, #bd2727);
                }

                &.yellow {
                    background: linear-gradient(45deg, #f3e214, #e7d820);
                }

                img {
                    width: 100%;
                }
            }

            .dark_mode_tilesData {
                h4 {
                    font-family: $font-mulish  !important;
                    font-size: 17px;
                    color: white !important;
                }

                .TilesAmount {
                    color: white !important;
                    font-size: 22px;
                }
            }
        }
    }

    .summary {
        padding: $summary-padding;
        position: relative;
        margin-bottom: $summary-margin-bottom;

        .dark_mode_tabContent {
            flex-wrap: wrap;
            width: 100%;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
            padding: $table-content-padding;
            background: #112237 !important;
            // border-radius: $table-content-border-radius;
            border-radius: 2%;

            .TabPanel {
                border: none;
                padding: 0 1em;
                padding-top: 2px;

                @media only screen and (max-width: 991px) {
                    width: 100%;
                }

                @media only screen and (max-width: 600px) {
                    width: 100%;
                    padding: 0px;
                }
            }

            .dataTable {
                padding-top: $tab-content-padding-top;

                .filterSection {
                    .filterDropDown {
                        height: 36px !important;
                        min-width: 155px;
                        border-radius: 0px !important;
                        border: 1px solid #cfdadd !important;
                        width: 150px;

                        .selectedCatgory {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            padding: 5px 19px !important;
                            cursor: pointer;

                            .caretIcon {
                                display: inline-block;
                                width: 0;
                                height: 0;
                                margin-left: 5px;
                                vertical-align: middle;
                                border-top: 4px dashed;
                                border-right: 4px solid transparent;
                                border-left: 4px solid transparent;
                                position: absolute;
                                right: 10%;
                                top: 40%;
                            }
                        }

                        @media only screen and (max-width: 600px) {
                            min-width: 100%;
                        }
                    }
                }

                .filterButtons {
                    button {
                        padding: 10px;
                        margin-left: 5px;

                        @media only screen and (max-width: 907px) {
                            margin-top: 20px;
                        }
                    }
                }

                .tables {
                    padding-top: 10px;
                    font-family: system-ui;

                }
            }
        }
    }
}

.badge {
    display: inline-block;
    padding: .35em .5em;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 3.25rem;
    min-width: 10px;
    text-shadow: 0 1px 0 rgba(252, 213, 213, 0.2);

    &.badgegreen {
        color: #11a666;
        background-color: #dbf2e8;
    }

    &.badgeblue {
        color: #7266ba;
        background-color: #7266ba54;
    }
}

.status {
    font-size: 15px;

    &.active {
        color: #11a666;
    }

    &.used {
        color: #FF8C00;
    }

    &.expired {
        color: #dc3545;
    }
}

.ModalDialog {
    position: fixed;
    margin: auto;
    width: 35%;
    height: 100%;
    right: 0;
    max-width: 50%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);

    .ModalHeader {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);

        button {
            // margin-right: 25px; 
            margin-right: -6%;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #b9b6b6;
        }
    }

    .ModalBodyHeader {
        font-size: 30px;
        color: #3b5698;
        margin-top: 0px;
        padding-bottom: 18px;
    }
}

.refundText {
    color: red;
}

.required::after {
    content: "*";
    color: red;
    padding-left: 5px;
}

.isInvalid {
    margin-top: -1px !important;
    color: #a94442;
}

.Forms {
    // background-color: #f7f7f7 !important;
    padding: 5px 12px !important;
    border-radius: 3px !important;
    border: 1px solid #cfdadd;
    height: 34px;
    color: black !important;

    &:disabled {
        cursor: not-allowed;
    }
}

.submitButton {
    background-color: $color-primary;
    height: $input-height;
    border-color: $color-primary;

    &:hover {
        background-color: $color-primary;
        border-color: $color-primary;
    }

    &:focus {
        background-color: $color-primary;
        border-color: $color-primary;
    }
}

.submitButton:active {
    background-color: $color-primary  !important;
    border-color: $color-primary  !important;
}

.reqepindatevalid {
    position: relative;
    top: -19px;
}

@media only screen and (max-width: 907px) {
    .multiselect-container {
        margin-bottom: 20px;
    }
}

/****************************** end of dark mode **************************************/