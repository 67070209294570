@import "../../style/custonVariable";
.MainContainer {
  // position: relative;
  // background: $panel-bg-color;
  height: 100%;
  margin-top: $tiles-gap-height;
  @media (max-width: 440px) {
    margin-top: 2px;
  }
  .ewalletTiles {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14%, 1fr));
    // grid-gap: 20px;
    grid-gap: 10px;
    margin-top: 3px;
    padding: 11px 20px;
    margin: 0 auto 0px auto;
    max-width: 100%;
    width: 100%;
    @media (max-width: 991px) {
      grid-template-columns: 1fr;
    }
    .Tiles {
      min-height: $tiles-min-height;
      background-color: $panel-bg-color;
      // padding: 15px 20px;
      padding: 8px 5px;
      border-radius: 10px;
      // position: relative;
      // box-shadow: 0px 1px 3px rgba(0,0,0,0.1);
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
      align-items: center;
      margin-bottom: 0px;
      margin-top: -$tiles-gap-height + 2;
      @media (max-width: 440px) {
        min-height: 90px;
        padding: 15px 20px;
      }
      @media (max-width: 991px) {
        margin-top: 0;
      }
      .leftIcon {
        padding: 10px;
        margin-right: 10px;
        border-radius: 10%;
        width: 55px;
        height: 55px;
        display: table;
        align-items: center;
        &.green {
          background: linear-gradient(to top, #5bc554 0%, #11a666 100%);
        }
        &.blue {
          background: linear-gradient(45deg, #44badc, #519eaf);
        }
        &.violet {
          background: linear-gradient(45deg, #276ded, #29525a);
        }
        &.pink {
          background: linear-gradient(45deg, #e127ed, #29525a);
        }
        &.red {
          background: linear-gradient(45deg, #e92222cc, #bd2727);
        }
        img {
          width: 100%;
        }
      }
      .tilesData {
        h4 {
          font-family: $font-mulish !important;
          font-size: 17px;
          color: rgb(88, 102, 110);
        }
        .TilesAmount {
          color: rgb(88, 102, 110);
          font-size: 18px;
        }
      }
    }
  }
  .summary {
    padding: 20px;
    position: relative;
    margin-bottom: $summary-margin-bottom;
    .tabContent {
      flex-wrap: wrap;
      width: 100%;
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
      background: #fff;
      padding: 25px;
      border-radius: 2%;
      @media (max-width: 767px) {
        padding: 0;
      }
      .TabPanel {
        border: none;
        padding: 0 1em;
        padding-top: 5px;
        @media (max-width: 767px) {
          width: 100%;
          overflow: scroll;
          padding-top: 10px;
        }
      }
      .DataTable {
        table {
          thead {
            border-bottom: 1px solid rgb(226, 223, 223);
          }
        }
      }
      .dataTable {
        padding-top: 15px;
        @media (max-width: 767px) {
          border: 1px solid #ddd;
          padding: 5px;
        }

        .filterSection {
          ._3vt7_Mh4hRCFbp__dFqBCI input {
            font-size: 15px;
          }
          display: inline;
          .DateRangePicker {
            background: #fff;
            cursor: pointer;
            padding: 5px;
            border: 1px solid #ccc;
            min-width: 10%;
            max-width: max-content;
            display: inline-block;
            margin-right: 5px;
            font-size: 15px;
            @media (max-width: 767px) {
              margin-bottom: 5px;
              width: 100%;
              max-width: none;
            }
          }
          @media (max-width: 767px) {
            display: block;
          }
          .filterButton {
            display: inline;

            @media (max-width: 767px) {
              display: block;
            }
            .padding5 {
              padding: 10px;
              margin-right: 3px;
            }
            @media (max-width: 600px) {
              margin-right: 8px;
              margin-top: 10px;
              margin-bottom: 5px;
            }
          }
          ._2iA8p44d0WZ-WqRBGcAuEV {
            margin-bottom: 10px !important;
          }
          .exportButton {
            display: inline;
            float: right;
            button {
              background-color: $color-primary;
              height: $input-height;
              border-color: $color-primary;
              border-radius: 0.1875rem;
              margin-right: 0.333em;
              margin-bottom: 0.333em;
              margin-top: 10px;
              @media (max-width: 600px) {
                margin-right: 8px;
                margin-top: 10px;
                margin-bottom: 5px;
              }
              i {
                text-align: center;
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 2px 0 0 2px;
                position: relative;
                float: left;
                width: 30px;
                height: 30px;
                margin: -6px -12px;
                margin-right: -12px;
                margin-right: -12px;
                margin-right: 3px;
                line-height: 30px;
              }
            }
          }
        }
      }
    }
  }
}

.badge {
  display: inline-block;
  padding: 0.35em 0.5em;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3.25rem;
  min-width: 10px;
  text-shadow: 0 1px 0 rgba(252, 213, 213, 0.2);
  &.credit {
    color: #11a666;
    background-color: #dbf2e8;
  }
  &.balanceAmount {
    color: #7266ba;
    background-color: #7266ba54;
  }
  &.debit {
    color: #f85974;
    background-color: #f6dee2;
  }
}
.ModalDialog {
  position: fixed;
  margin: auto;
  width: 35%;
  height: 100%;
  right: 0;
  max-width: 50%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  @media (max-width: 991px) {
    max-width: 90%;
    width: 100%;
  }
  .ModalHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 17px 1rem;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    button {
      // margin-right: 0px;
      margin-right: -6%;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #b9b6b6;
    }
  }
  .ModalBodyHeader {
    font-size: 30px;
    color: #3b5698;
    margin-top: 0px;
  }
}
.required::after {
  content: "*";
  color: red;
  padding-left: 5px;
}
.invalidForm {
  &:invalid {
    border-color: #dee2e6 !important;
  }
  &:valid {
    border-color: #dee2e6 !important;
    background-image: none !important;
  }
}
.invalidFeedBack {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.TabClass {
  background: transparent;
  border-style: none;
  border-color: transparent;
  border-width: 0;
  @media (max-width: 767px) {
    border: none;
    margin-bottom: 1px;
  }
}
.description {
  i {
    color: red;
  }
}
@media (max-width: 1366px) {
  .ewalletTiles h4 {
    min-height: 40px;
    display: flex;
    align-items: center;
  }
}

/********************************** DARK MODE****************************************/
.MainContainer {
  // position: relative;
  // background: $panel-bg-color;
  height: 100%;
  margin-top: $tiles-gap-height;

  @media (max-width: 440px) {
    margin-top: 2px;
  }

  .ewalletTiles {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14%, 1fr));
    // grid-gap: 20px;
    grid-gap: 10px;
    margin-top: 3px;
    padding: 11px 20px;
    margin: 0 auto 0px auto;
    max-width: 100%;
    width: 100%;

    @media (max-width: 991px) {
      grid-template-columns: 1fr;
    }

    .dark_mode_Tiles {
      min-height: $tiles-min-height;
      background-color: hsla(0, 0%, 100%, 0.058823529411764705) !important;
      // padding: 22px 20px;
      padding:8px 5px;
      border-radius: 5%;
      // position: relative;
      // box-shadow: 0px 1px 3px rgba(0,0,0,0.1);
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
      align-items: center;
      margin-bottom: 0px;
      margin-top: -$tiles-gap-height + 2;

      @media (max-width: 440px) {
        min-height: 90px;
        padding: 15px 20px;
      }

      @media (max-width: 991px) {
        margin-top: 0;
      }

      .leftIcon {
        padding: 10px;
        margin-right: 10px;
        border-radius: 10%;
        width: 55px;
        height: 55px;
        display: table;
        align-items: center;

        &.green {
          background: linear-gradient(to top, #5bc554 0%, #11a666 100%);
        }

        &.blue {
          background: linear-gradient(45deg, #44badc, #519eaf);
        }

        &.violet {
          background: linear-gradient(45deg, #276ded, #29525a);
        }

        &.pink {
          background: linear-gradient(45deg, #e127ed, #29525a);
        }

        &.red {
          background: linear-gradient(45deg, #e92222cc, #bd2727);
        }

        img {
          width: 100%;
        }
      }

      .dark_mode_tilesData {
        h4 {
          font-family: $font-mulish !important;
          font-size: 17px;
          color: white !important;
        }

        .TilesAmount {
          color: white !important;
          font-size: 18px;
        }
      }
    }
  }

  .summary {
    padding: 20px;
    position: relative;
    margin-bottom: $summary-margin-bottom;

    .dark_mode_tabContent {
      flex-wrap: wrap;
      width: 100%;
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
      background: #0f2036;
      padding: 25px;
      border-radius: 2%;

      @media (max-width: 767px) {
        padding: 0;
      }

      .TabPanel {
        border: none;
        padding: 0 1em;
        padding-top: 5px;

        @media (max-width: 767px) {
          width: 100%;
          overflow: scroll;
          padding-top: 10px;
        }
      }

      .DataTable {
        table {
          thead {
            border-bottom: 1px solid rgb(226, 223, 223);
          }
        }
      }

      .dataTable {
        padding-top: 15px;

        @media (max-width: 767px) {
          border: 1px solid #ddd;
          padding: 5px;
        }

        .filterSection {
          ._3vt7_Mh4hRCFbp__dFqBCI input {
            font-size: 15px;
          }

          display: inline;

          .dark_mode_DateRangePicker {
            background: #14243a !important;
            cursor: pointer;
            padding: 5px;
            border: 1px solid #ccc;
            min-width: 10%;
            max-width: max-content;
            display: inline-block;
            margin-right: 5px;
            font-size: 15px;

            @media (max-width: 767px) {
              margin-bottom: 5px;
              width: 100%;
              max-width: none;
            }
          }

          @media (max-width: 767px) {
            display: block;
          }

          .filterButton {
            display: inline;

            @media (max-width: 767px) {
              display: block;
            }

            .padding5 {
              padding: 10px;
              margin-right: 3px;
            }

            @media (max-width: 600px) {
              margin-right: 8px;
              margin-top: 10px;
              margin-bottom: 5px;
            }
          }

          ._2iA8p44d0WZ-WqRBGcAuEV {
            margin-bottom: 10px !important;
          }

          .exportButton {
            display: inline;
            float: right;

            button {
              background-color: $color-primary;
              height: $input-height;
              border-color: $color-primary;
              border-radius: 0.1875rem;
              margin-right: 0.333em;
              margin-bottom: 0.333em;
              margin-top: 10px;
              @media (max-width: 600px) {
                margin-right: 8px;
                margin-top: 10px;
                margin-bottom: 5px;
              }

              i {
                text-align: center;
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 2px 0 0 2px;
                position: relative;
                float: left;
                width: 30px;
                height: 30px;
                margin: -6px -12px;
                margin-right: -12px;
                margin-right: -12px;
                margin-right: 3px;
                line-height: 30px;
              }
            }
          }
        }
      }
    }
  }
}

.badge {
  display: inline-block;
  padding: 0.35em 0.5em;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3.25rem;
  min-width: 10px;
  text-shadow: 0 1px 0 rgba(252, 213, 213, 0.2);

  &.dark_mode_credit {
    color: #11a666 !important;
    background-color: #304253;
  }

  &.balanceAmount {
    color: #7266ba;
    background-color: #7266ba54;
  }

  &.debit {
    color: #f85974;
    background-color: #f6dee2;
  }
}

.ewalod {
  padding: 10px;
  margin-left: 72%;
  margin-top: -5.5%;
}

.ModalDialog {
  position: fixed;
  margin: auto;
  width: 35%;
  height: 100%;
  right: 0;
  max-width: 50%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);

  @media (max-width: 991px) {
    max-width: 90%;
    width: 100%;
  }

  .ModalHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 17px 1rem;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);

    button {
      // margin-right: 0px;
      margin-right: -6%;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #b9b6b6;
    }
  }

  .ModalBodyHeader {
    font-size: 30px;
    color: #3b5698;
    margin-top: 0px;
  }
}

.required::after {
  content: "*";
  color: red;
  padding-left: 5px;
}

.invalidForm {
  &:invalid {
    border-color: #dee2e6 !important;
  }

  &:valid {
    border-color: #dee2e6 !important;
    background-image: none !important;
  }
}

.invalidFeedBack {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.TabClass {
  background: transparent;
  border-style: none;
  border-color: transparent;
  border-width: 0;

  @media (max-width: 767px) {
    border: none;
    margin-bottom: 1px;
  }
}

.description {
  i {
    color: red;
  }
}

@media (max-width: 1366px) {
  .ewalletTiles h4 {
    min-height: 40px;
    display: flex;
    align-items: center;
  }
}

.dark_mode_textArea {
  font-size: 15px;
  height: 70px !important;
  background-color: #253548 !important;
  color: white !important;
  border: none;
}

/********************************** END OF DARK MODE ********************************/
