@import '../../style/custonVariable';
.ModalContent {
    border: none;
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    background-color: $color-white;
    .ModalHeader {
      background-image: linear-gradient(to right, #7266ba, #4a3388);
      text-align: center;
      display: block;
      padding-right: 2rem;
      padding-left: 2rem;
      color: $color-white;
      border: none;
      h3 {
        margin-top: 20px;
        margin-bottom: 10px;
        i {
          -webkit-font-smoothing: antialiased;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          line-height: 1;
        }
      }
      .ModalTitle {
        font-size: 30px;
      }
      p {
        color: $color-white;
      }
    }
    .ModalBody {
      text-align: center;
      .forgotTag {
        color: red;
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
        cursor: pointer;
      }
      .captcha {
        text-align: center;
      }
      .captchaReload {
        cursor: pointer;
      }
      .SuccessModalButtonClose {
        text-align: center;
        padding: 25px;
        button {
          background-color: transparent;
          color: #7266ba;
          border: 1px solid #cfdadd;
          padding: 10px;
        }
      }
      .actionButton {
        text-align: center;
        .cancelButton {
          border: 1px solid #cfdadd;
          background-color: transparent;
          color: $color-primary;
          height: $input-height;
          border-radius: 0;
        }
        .changeButton {
          border-radius: 0;
          color: $color-white;
          background-color: $color-primary;
          border-color: $color-primary;
          height: $input-height;
          margin-left: 5px;
        }
      }
    }
  }

.MainContainer{
    position: relative;
    padding: 15px;
    .mainContent{
        .heading{
            font-size: 18px;
            text-transform: uppercase;
            color: #2C3E50;
            margin-bottom: 20px;
            margin-top: 20px;
            letter-spacing: 2px;
            text-align: center;
        }
       
        .totalAmount{
            text-align: center;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .SponsorPackage{
            background: $color-white;
            border: 0 none;
            border-radius: 0;
            box-shadow: 0 0 15px 1px rgba(0,0,0,0.4);
            padding: 10px 20px;
            box-sizing: border-box;
            width: 100%;
            position: relative;
        }
        .paymentMethod{
            background: $color-white;
            border: 0 none;
            border-radius: 0;
            box-shadow: 0 0 15px 1px rgba(0,0,0,0.4);
            padding: 10px 20px;
            box-sizing: border-box;
            width: 100%;
            // position: absolute;
        }
    }
    //registration preview
    .previewContent{
        .buttonsActions{
            text-align: right;
            .button{
                margin-left: 5px;
                background-color: $color-primary;
                border-color: $color-primary;
                font-size: 12px;
                margin-bottom: 10px;
            }
        }
        .cardBody{
            padding: 15px;
            .img{
                img{
                    width: 115px;
                }
            }
            .companyInfo{
                float: right;
                text-align: right;
                p{
                    margin: 0 0 10px;
                }
            }
            td{
                width: 50%;
            }
            tbody{
                color: #58666e;
            }
            tbody > tr:nth-child(2n+1) > td, tbody > tr:nth-child(2n+1) > th {
                background-color: $color-white;
                height: 42px;
            }
            tbody > tr:nth-child(2n) > td, tbody > tr:nth-child(2n) > th {
                height: 42px;
                background-color: #f9f9f9;
            }
            .regards{
                background-color: #f3f5f6;
                color: #58666e;
                border-color: #dee5e7;
                min-height: 20px;
                padding: 19px;
                margin-bottom: 20px;
                background-color: #f5f5f5;
                border: 1px solid #e3e3e3;
                border-radius: 4px;
                -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
            }
        }
    }
    .Buttons{
        text-align: center;
        .NextButton{
            background-color: $color-primary;
            border: 0 none;
            border-radius: 0;
            width: 100px;
            padding: 0 5px;
            margin: 10px 5px;
            height: 34px;
        }
        .PrevButton{
            background-color: $color-success;
            border: 0 none;
            border-radius: 0;
            width: 100px;
            padding: 0 5px;
            margin: 10px 5px;
            height: 34px;
        }
    }
}
.inputField{
    padding: 5px 12px !important;
    border-radius: 3px!important;
    border: 1px solid #cfdadd;
    height: 34px;
    color: black !important;
    &:disabled{
        cursor: not-allowed;
    }
}
.formsHasError{
    box-shadow: none;
    border: 1px solid #ead3d3 !important;
}
.isInvalid{
    margin-top: -1px;
    color: #a94442;
}
.required::after{
    content: "*";
    color: red;
    padding-left: 5px;
}
.checkbox{
    display: flex;
    align-items: center;
    padding: .5rem;
    .checkboxLabel{
        flex-shrink: 0;
        padding: .5rem 1rem;
        color: $checkbox__label__color;
        cursor: pointer;
        @media (max-width:768px) {
            width: 100%;
            font-size: 12px;
        }
    }
    .checkboxInput{
        position: relative;
        flex-shrink: 0;
        width: $checkbox__size;
        height: $checkbox__size;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none; // TODO: manage :focus
        background: $color__white;
        cursor: pointer;
        border: $checkbox__border__size solid $checkbox__border__color;

        &::before {
            content: ' ';
            position: absolute;
            top: 50%;
            right: 50%;
            bottom: 50%;
            left: 50%;
            transition: all .1s;
            background: $checkbox__checkmark__color;
          }
        &:checked,
        &:indeterminate {
            &::before {
                    top: $checked_gap-size;
                    right: $checked_gap-size;
                    bottom: $checked_gap-size;
                    left: $checked_gap-size;
                }
            }
            &:indeterminate {
                &::before {
                  top: ($checkbox__checkmark__size / 2) - ($checkbox__checkmark__size--indeterminate / 2);
                  bottom: ($checkbox__checkmark__size / 2) - ($checkbox__checkmark__size--indeterminate / 2);
                }
            }
            &:disabled {
                border-color: $checkbox__border__color--disabled;
                cursor: default;
          
                &::before {
                  background-color: $checkbox__checkmark__color--disabled;
                }
          
                + .m-checkbox__label {
                  color: $checkbox__label__color--disabled;
                  cursor: default;
                }
              }
    }
}
.ModalContent{
    border: none;
    background-color: white;
}
.termsModalheader{
    background-color: #7266ba;
    text-align: center;
    color: #fff;
    display: block;

}
.modal.show .modal-dialog{width: 95% !important;}



/************************************** DARK MODE ************************************/
.MainContainer{
    position: relative;
    padding: 15px;
    .mainContent{
        .dark_mode_heading{
            font-size: 18px;
            text-transform: uppercase;
            color: white !important;
            margin-bottom: 20px;
            margin-top: 20px;
            letter-spacing: 2px;
            text-align: center;
        }
        .totalAmount{
            text-align: center;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .dark_mode_SponsorPackage{
            background: #112237 !important;
            border: 0 none;
            border-radius: 0;
            box-shadow: 0 0 15px 1px rgba(0,0,0,0.4);
            padding: 10px 20px;
            box-sizing: border-box;
            width: 100%;
            position: relative;
        }
        .dark_mode_paymentMethod{
            background: #112237 !important;
            border: 0 none;
            border-radius: 0;
            box-shadow: 0 0 15px 1px rgba(0,0,0,0.4);
            padding: 10px 20px;
            box-sizing: border-box;
            width: 100%;
            // position: absolute;
        }
    }
    //registration preview
    .previewContent{
        .buttonsActions{
            text-align: right;
            .button{
                margin-left: 5px;
                background-color: $color-primary;
                border-color: $color-primary;
                font-size: 12px;
                margin-bottom: 10px;
            }
        }
        .dark_mode_cardBody{
            padding: 15px;
            .img{
                img{
                    width: 115px;
                }
            }
            .companyInfo{
                float: right;
                text-align: right;
                p{
                    margin: 0 0 10px;
                }
            }
            td{
                width: 50%;
            }
            tbody{
                color: #ffffff;
            }
            tbody > tr:nth-child(2n+1) > td, tbody > tr:nth-child(2n+1) > th {
                background-color: #14243A;
                height: 42px;
            }
            tbody > tr:nth-child(2n) > td, tbody > tr:nth-child(2n) > th {
                height: 42px;
                background-color: #14243A;
            }
            .dark_mode_regards{
                background-color: #14243A;
                color: #ffffff;
                border-color: #dee5e7;
                min-height: 20px;
                padding: 19px;
                margin-bottom: 20px;
                background-color: #14243A;
                border: 1px solid #e3e3e3;
                border-radius: 4px;
                -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
            }
        }
    }
    .Buttons{
        text-align: center;
        .NextButton{
            background-color: $color-primary;
            border: 0 none;
            border-radius: 0;
            width: 100px;
            padding: 0 5px;
            margin: 10px 5px;
            height: 34px;
        }
        .PrevButton{
            background-color: $color-success;
            border: 0 none;
            border-radius: 0;
            width: 100px;
            padding: 0 5px;
            margin: 10px 5px;
            height: 34px;
        }
    }
}
.dark_mode_inputField{
    padding: 5px 12px !important;
    border-radius: 3px!important;
    border: 1px solid #cfdadd;
    height: 34px;
    color: white !important;
    &:disabled{
        cursor: not-allowed;
    }
}
.formsHasError{
    box-shadow: none;
    border: 1px solid #ead3d3 !important;
}
.isInvalid{
    margin-top: -1px;
    color: #a94442;
}
.required::after{
    content: "*";
    color: red;
    padding-left: 5px;
}
.checkbox{
    display: flex;
    align-items: center;
    padding: .5rem;
    .checkboxLabel{
        flex-shrink: 0;
        padding: .5rem 1rem;
        color: $checkbox__label__color;
        cursor: pointer;
        @media (max-width:768px) {
            width: 100%;
            font-size: 12px;
        }
    }
    .checkboxInput{
        position: relative;
        flex-shrink: 0;
        width: $checkbox__size;
        height: $checkbox__size;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none; // TODO: manage :focus
        background: $color__white;
        cursor: pointer;
        border: $checkbox__border__size solid $checkbox__border__color;

        &::before {
            content: ' ';
            position: absolute;
            top: 50%;
            right: 50%;
            bottom: 50%;
            left: 50%;
            transition: all .1s;
            background: $checkbox__checkmark__color;
          }
        &:checked,
        &:indeterminate {
            &::before {
                    top: $checked_gap-size;
                    right: $checked_gap-size;
                    bottom: $checked_gap-size;
                    left: $checked_gap-size;
                }
            }
            &:indeterminate {
                &::before {
                  top: ($checkbox__checkmark__size / 2) - ($checkbox__checkmark__size--indeterminate / 2);
                  bottom: ($checkbox__checkmark__size / 2) - ($checkbox__checkmark__size--indeterminate / 2);
                }
            }
            &:disabled {
                border-color: $checkbox__border__color--disabled;
                cursor: default;
          
                &::before {
                  background-color: $checkbox__checkmark__color--disabled;
                }
          
                + .m-checkbox__label {
                  color: $checkbox__label__color--disabled;
                  cursor: default;
                }
              }
    }
}
.ModalContent{
    border: none;
    background-color: white;
}
.termsModalheader{
    background-color: #7266ba;
    text-align: center;
    color: #fff;
    display: block;

}
.modal.show .modal-dialog{width: 95% !important;}

.dark_mode_modal_body {
    color:white !important;
    p{
        color:white !important;
        padding:1rem;
    }
}

.modal_body{
    color:black !important
}

.dark_mode_innerhtml {
    p{
        color:white
    }
}

innerHtml{
    p{
        color:black
    }
}

/************************************** END OF DARK MODE ****************************/

