@import "../../style/custonVariable";

.buyBtn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    text-decoration: none;
    cursor: pointer;
}

.MianContent {
    padding-top: 10px !important;

    .recieptPreview {
        width: 20%;
        height: 20%;
    }

    .RecieptRemoveButton {
        background: rgb(13, 187, 240);
        border: 1px solid transparent;
        border-radius: 0;
        margin-left: 10px;

        &:hover {
            background-color: rgb(13, 187, 240);
        }

        &:focus {
            background-color: rgb(13, 187, 240);

        }
    }

    .RecieptRemoveButton:active {
        background-color: rgb(13, 187, 240) !important;

    }

    .required::after {
        content: "*";
        color: red;
        padding-left: 5px;
    }

    .FormError {
        color: red;

        i {
            padding-right: 5px;
        }
    }

    .FormSuccess {
        i {
            color: green;
        }
    }

    .textdanger {
        color: red;
        font-size: 15px;
    }

    .Buttons {
        text-align: center;

        .NextButton {
            background-color: $color-primary;
            border: 0 none;
            border-radius: 0;
            width: 100px;
            padding: 0 5px;
            margin: 10px 5px;
            height: $input-height;
        }

        .PrevButton {
            background-color: $color-success;
            border: 0 none;
            border-radius: 0;
            width: 100px;
            padding: 0 5px;
            margin: 10px 5px;
            height: $input-height;
        }

        .checkAvailable {
            background-color: $color-primary;
            border: 0 none;
            border-radius: 0;
            width: auto;
            padding: 0 5px;
            margin: 10px 5px;
            height: $input-height;
        }

        .uploadButton {
            background-color: $color-info;
            border: 0 none;
            border-radius: 0;
            width: 100px;
            padding: 0 5px;
            margin: 10px 5px;
            height: $input-height;

            i {
                position: relative;
                width: 34px;
                height: 34px;
                margin: -7px -12px;
                margin-right: 12px;
                line-height: 34px;
                text-align: center;
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 2px 0 0 2px;
            }
        }
    }

    .epinContent {
        border-color: #dee5e7;
        border-radius: 2px;
        margin-bottom: 20px;
        background-color: #fff;
        border: 1px solid transparent;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .05);

        .EpinTable {
            thead {
                background-color: $color-gray;
                color: $color-black;
            }

            tr {
                max-width: 10px;

                th,
                td {
                    max-width: 150px;
                    word-break: break-all;
                }

                td {

                    // max-width: 100%;
                    input {
                        width: 105px !important;
                        margin-bottom: 0 !important;
                        text-align: center;
                    }

                    .messages {
                        padding: 5px;
                        font-size: 14px;

                        .validIcon {
                            color: green;
                        }

                        .invalid {
                            color: red;
                        }

                        i {
                            margin: 0 5px;
                        }
                    }
                }
            }
        }

        .epinList {
            background-color: #eee;
            padding: 10px;
            margin-bottom: 15px;

            .listView {
                background-color: $color-white;
                padding: 12px 10px;
                color: #777;
                font-size: 14px;
                margin: 7px 0;
                border: 1px solid #e7ecee;
                text-align: left;

                .epinAmount {
                    float: right;

                    b {
                        color: #776abc;
                        margin-right: 10px;
                    }

                    .removeButton {
                        color: #f03737;
                        padding-left: 5px;
                        cursor: pointer;
                    }
                }
            }

            .epinTotoalamount {
                text-align: right;
                margin-right: 25px;
                padding-top: 15px;

                b {
                    margin-left: 12px;
                    font-size: 17px;
                }
            }
        }

        .noDataList {
            text-align: center;
        }
    }

    .ButtonGroup {
        // float: left;
        text-align: center;

        .invalid {
            color: red;
            width: 45%;
            margin: 0 auto;
            text-align: left;
        }

        .inLineBlock {
            display: inline-block;

            .inputField {
                padding: 10px;
                border: 1px solid #e8e8e8;
                border-radius: 0px;
                height: 34px;
                width: 100%;
                box-sizing: border-box;
                color: #2C3E50;
                font-size: 15px;
                height: $input-height;


            }

            .ApplyButton {
                // background-color: $color-primary;
                background-color: $color-primary;
                border: 0 none;
                border-radius: 0;
                width: 100px;
                padding: 0 5px;
                margin-left: 10px;
                margin-top: -5px;
                // margin: 10px 5px;
                height: $input-height;

                @media only screen and (max-width:475px) {

                    margin-top: 15px;
                }
            }
        }
    }
}

// .inputField{
//     height: $input-height;
// }
.PurchaseWallet {
    .purchaseCol {
        padding: 0;
        padding-left: 3px;

        .checkAvailability {
            padding-left: 3px;
            padding-right: 0;
            margin-top: 26px;

            .checkAvailabityBtn {
                background-color: $color-primary;
                border-color: $color-primary;
                height: 34px;

                &::not(:disabled):not(.disabled):active {
                    background-color: $color-primary;
                    border-color: $color-primary;
                }
            }
        }
    }
}


/******************************************** dark mode *********************************************/
.dark_mode_MianContent {
    padding-top: 10px !important; 

    .bankDetails {
        background-color: #112237 !important;
    }

    .recieptPreview {
        width: 20%;
        height: 20%;
    }

    .RecieptRemoveButton {
        background: rgb(13, 187, 240);
        border: 1px solid transparent;
        border-radius: 0;
        margin-left: 10px;

        &:hover {
            background-color: rgb(13, 187, 240);
        }

        &:focus {
            background-color: rgb(13, 187, 240);

        }
    }

    .RecieptRemoveButton:active {
        background-color: rgb(13, 187, 240) !important;

    }

    .required::after {
        content: "*";
        color: red;
        padding-left: 5px;
    }

    .FormError {
        color: red;

        i {
            padding-right: 5px;
        }
    }

    .FormSuccess {
        i {
            color: green;
        }
    }

    .textdanger {
        color: red;
        font-size: 15px;
    }

    .Buttons {
        text-align: center;

        .NextButton {
            background-color: $color-primary;
            border: 0 none;
            border-radius: 0;
            width: 100px;
            padding: 0 5px;
            margin: 10px 5px;
            height: $input-height;
        }

        .PrevButton {
            background-color: $color-success;
            border: 0 none;
            border-radius: 0;
            width: 100px;
            padding: 0 5px;
            margin: 10px 5px;
            height: $input-height;
        }

        .checkAvailable {
            background-color: $color-primary;
            border: 0 none;
            border-radius: 0;
            width: auto;
            padding: 0 5px;
            margin: 10px 5px;
            height: $input-height;
        }

        .uploadButton {
            background-color: $color-info;
            border: 0 none;
            border-radius: 0;
            width: 100px;
            padding: 0 5px;
            margin: 10px 5px;
            height: $input-height;

            i {
                position: relative;
                width: 34px;
                height: 34px;
                margin: -7px -12px;
                margin-right: 12px;
                line-height: 34px;
                text-align: center;
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 2px 0 0 2px;
            }
        }
    }

    .epinContent {
        border-color: #dee5e7;
        border-radius: 2px;
        margin-bottom: 20px;
        background-color: #fff;
        border: 1px solid transparent;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .05);

        .EpinTable {
            thead {
                background-color: $color-gray;
                color: $color-black;
            }

            tr {
                max-width: 10px;

                th,
                td {
                    max-width: 150px;
                    word-break: break-all;
                }

                td {

                    // max-width: 100%;
                    input {
                        width: 105px !important;
                        margin-bottom: 0 !important;
                        text-align: center;
                    }

                    .messages {
                        padding: 5px;
                        font-size: 14px;

                        .validIcon {
                            color: green;
                        }

                        .invalid {
                            color: red;
                        }

                        i {
                            margin: 0 5px;
                        }
                    }
                }
            }
        }

        .epinList {
            background-color: #eee;
            padding: 10px;
            margin-bottom: 15px;

            .listView {
                background-color: $color-white;
                padding: 12px 10px;
                color: #777;
                font-size: 14px;
                margin: 7px 0;
                border: 1px solid #e7ecee;
                text-align: left;

                .epinAmount {
                    float: right;

                    b {
                        color: #776abc;
                        margin-right: 10px;
                    }

                    .removeButton {
                        color: #f03737;
                        padding-left: 5px;
                        cursor: pointer;
                    }
                }
            }

            .epinTotoalamount {
                text-align: right;
                margin-right: 25px;
                padding-top: 15px;

                b {
                    margin-left: 12px;
                    font-size: 17px;
                }
            }
        }

        .noDataList {
            text-align: center;
        }
    }

    .ButtonGroup {
        // float: left;
        text-align: center;

        .invalid {
            color: red;
            width: 45%;
            margin: 0 auto;
            text-align: left;
        }

        .inLineBlock {
            display: inline-block;

            .inputField {
                padding: 10px;
                border: 1px solid #e8e8e8;
                border-radius: 0px;
                height: 34px;
                width: 100%;
                box-sizing: border-box;
                color: #2C3E50;
                font-size: 15px;
                height: $input-height;


            }

            .ApplyButton {
                // background-color: $color-primary;
                background-color: $color-primary;
                border: 0 none;
                border-radius: 0;
                width: 100px;
                padding: 0 5px;
                margin-left: 10px;
                margin-top: -5px;
                // margin: 10px 5px;
                height: $input-height;

                @media only screen and (max-width:475px) {

                    margin-top: 15px;
                }
            }
        }
    }
}

// .inputField{
//     height: $input-height;
// }
.PurchaseWallet {
    .purchaseCol {
        padding: 0;
        padding-left: 3px;

        .checkAvailability {
            padding-left: 3px;
            padding-right: 0;
            margin-top: 26px;

            .checkAvailabityBtn {
                background-color: $color-primary;
                border-color: $color-primary;
                height: 34px;

                &::not(:disabled):not(.disabled):active {
                    background-color: $color-primary;
                    border-color: $color-primary;
                }
            }
        }
    }
}

.dark_mode_textArea {
    font-size: 15px;
    height: 70px !important;
    background-color: #253548 !important;
    color: white !important;
    border: none;
}

/***************************************end of dark mode *******************************************/